<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="supportTeamForm">
      <branch-select v-model="branch" :company="selectedCompany" :reduce="(option) => option._id" required @input="getEmployeeOptions" />
      <app-select-input
        v-model="employees"
        name="Çalışan"
        :options="employeeOptions"
        :reduce="(option) => option._id"
        label="Çalışan:"
        select_label="fullname"
        placeholder="Çalışan Seçiniz..."
        rules="required"
        multiple
      />
      <hr />
      <app-button text="Kaydet" block @click="createMany" />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data() {
    return {
      employeeOptions: [],
      employees: [],
      branch: null,
    };
  },
  computed: {
    supportType() {
      return this.$store.getters.appModalSidebarItem?.employeeType;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
  },
  mounted() {
    this.branch = this.selectedBranch;
    this.getEmployeeOptions();
  },
  methods: {
    getEmployeeOptions() {
      if (this.selectedCompany && this.branch) {
        this.$axios
          .get("/employees/support-team/not-assigned-list", { params: { company: this.selectedCompany, branch: this.branch, supportType: this.supportType } })
          .then((res) => {
            this.employeeOptions = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.employeeOptions = [];
        this.employees = [];
      }
    },
    createMany() {
      this.$validate(this.$refs.supportTeamForm, () => {
        this.$axios
          .post(
            "/employees/support-team/many",
            {
              employees: this.employees,
              supportType: this.supportType,
              company: this.selectedCompany,
              branch: this.branch,
            },
            { loading: "sidebar" }
          )
          .then((res) => {
            this.$store.commit("setNewSupportTeams", res.data);
            this.$emitter.$emit("Notification", {
              title: "Çalışanlar Eklendi.",
              variant: "success",
            });
            this.$store.commit("cleanAppModalSidebar");
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
  watch: {
    branch() {
      this.employees = [];
      this.getEmployeeOptions();
    },
  },
};
</script>

<style></style>
