<template>
  <app-overlay>
    <db-searchable-table
      class="border m-0"
      v-model="filters"
      @changed="getData"
      :items="employees"
      :fields="fields"
      :total-rows="totalCount"
      hover
      @row-clicked="showDetails"
      searchable
    >
      <template #modalbutton>
        <div class="d-flex gap-5 flex-wrap">
          <b-dropdown v-if="!isCompanyArchived" class="flex-1-md" split text="Primary" variant="primary" @click="$showAppSidebar('Çalışan Ekle', EmployeeForm)">
            <template #button-content>
              <span class="d-flex align-items-center fs-14 gap-4 no-wrap">Çalışan Ekle </span>
            </template>
            <b-dropdown-item @click="showCreateManyForm">
              <span class="d-flex align-items-center gap-10"><feather-icon icon="UploadIcon" size="13" /> Toplu Ekle </span>
            </b-dropdown-item>
          </b-dropdown>
          <app-button
            text="Destek Elemanları"
            size="md"
            icon="UsersIcon"
            class="flex-1-md"
            @click="$showAppModal({ title: 'Destek Elemanları', component: supportedEmployees, size: 'custom', centered: false })"
          />
          <app-button
            class="flex-1-md"
            text="Çalışan Temsilcisi"
            icon="UserIcon"
            size="md"
            @click="$showAppModal({ title: 'Çalışan Temsilcisi', component: EmployeeRepresentative, size: 'custom', centered: false })"
          />
        </div>
      </template>
      <template #status="{ item }">
        <b-badge :variant="item.isArchived ? 'secondary' : 'success'">
          {{ item.isArchived ? "Arşivlendi" : "Aktif" }}
        </b-badge>
      </template>
      <template #actions="{ item }">
        <app-icon-button v-if="!item.isArchived" variant="gradient-danger" icon="TrashIcon" @click="archiveEmployee(item)" />
        <span v-else> - </span>
      </template>
      <template #confirmation="{ item }">
        <b-badge v-if="item.confirmation" :variant="$variants[item.confirmation.status]">
          {{ $capitalize(item.confirmation.status) }}
        </b-badge>
        <span v-else> - </span>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import supportedEmployees from "./supportedEmployees/SupportedEmployeesList.vue";
import EmployeeRepresentative from "./EmployeeRepresentative.vue";
import EmployeeForm from "./EmployeeForm.vue";
import EmployeeDetailModal from "./details/EmployeeDetailModal.vue";
import CreateFromTemplateForm from "./CreateFromTemplateForm.vue";

export default {
  data() {
    return {
      filters: {},
      supportedEmployees,
      EmployeeForm,
      EmployeeRepresentative,
      CreateFromTemplateForm,
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    isCompanyArchived() {
      return this.$store.getters.selectedCompany?.isArchived;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    employees() {
      return this.$store.getters.employees?.data || [];
    },
    totalCount() {
      return this.$store.getters.employees?.total || 0;
    },
    breakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
    fields() {
      return this.breakPoint === "xs"
        ? [
            { key: "fullname", label: "Ad Soyad", sortable: true },
            { key: "actions", label: "Eylemler" },
          ]
        : [
            ...(this.selectedBranch ? [] : [{ key: "branch.name", label: "Sicil" }]),
            { key: "fullname", label: "Ad Soyad", sortable: true },
            { key: "phone", label: "Telefon" },
            { key: "duty", label: "Görevi" },
            { key: "status", label: "Durum" },
            { key: "actions", label: "Eylemler", sortable: false },
          ];
    },
  },
  methods: {
    getData() {
      this.selectedCompany &&
        this.$store.dispatch("getEmployees", {
          query: {
            ...this.filters,
            company: this.selectedCompany?._id,
            branch: this.selectedBranch ? this.selectedBranch : undefined,
            searchFields: JSON.stringify(["fullname"]),
          },
          loading: "table",
        });
    },
    showCreateManyForm() {
      this.$showAppSidebar("Toplu Ekle", CreateFromTemplateForm, {
        ...this.filters,
        company: this.selectedCompany?._id,
        branch: this.selectedBranch ? this.selectedBranch : undefined,
      });
    },
    update(data) {},
    showDetails(employee) {
      this.$showAppModal({
        title: "Çalışan Detayları",
        component: EmployeeDetailModal,
        size: "custom",
        centered: false,
        item: { ...employee },
      });
    },
    archiveEmployee(employee) {
      this.$confirm({ message: "Çalışanı arşivlemek istediğinize emin misiniz?" }, () => this.$store.dispatch("archiveEmployee", employee?._id));
    },
  },

  mounted() {
    /*  this.$socket.on("NEW_EMPLOYEE", (data) => {
      if (this.selectedCompany?._id === data.company && this.selectedBranch === data.branch._id) this.$store.commit("setEmployee", data);
    }); */
  },
  destroyed() {
    this.$store.commit("setEmployees", []);
    /*  this.$socket.off("NEW_EMPLOYEE"); */
  },

  watch: {
    selectedBranch() {
      this.getData();
    },
    isCompanyArchived() {
      this.getData();
    },
  },
};
</script>
