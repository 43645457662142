<template>
  <app-overlay>
    <db-searchable-table v-model="filters" :items="items" @changed="getData" :fields="fields" :total-rows="total" searchable>
      <template #modalbutton>
        <app-button @click="showForm" text="Kişi Ekle" icon="PlusIcon" size="md" />
      </template>
      <template #actions="{ item }">
        <app-icon-button @click="deleteContact(item)" variant="gradient-danger" icon="TrashIcon" />
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import CompanyContactForm from "./CompanyContactForm.vue";
export default {
  data() {
    return {
      filters: {},
      items: [],
      fields: [
        { key: "fullname", label: "İsim" },
        { key: "phone", label: "Telefon" },
        { key: "duty", label: "Görevi" },
        { key: "actions", label: "İşlemler" },
      ],
      total: 0,
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
  },
  methods: {
    getData() {
      const filter = this?.selectedCompany?.parasut_contact_id ? { parasut_contact_id: this?.selectedCompany?.parasut_contact_id } : { company: this?.selectedCompany?._id };
      this.$axios
        .get("/company/contacts/list", { params: { ...this.filters, ...filter }, loading: "table" })
        .then((result) => {
          this.items = result?.data?.data ?? [];
          this.total = result?.data?.total ?? 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showForm() {
      this.$showAppSidebar("Kişi Ekle", CompanyContactForm, { callback: this.getData });
    },
    deleteContact(item) {
      this.$confirm({ message: "Kişi silinecektir emin misiniz" }, () => {
        this.$axios
          .delete(`/company/contacts/${item?._id}`)
          .then(() => {
            this.getData();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style></style>
