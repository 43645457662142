<template>
  <div>
    <company-tabs id="company-details" :tabs="tabs" lazy>
      <template slot="leftSide">
        <b-tooltip v-if="selectedCompany && isTruncated" :target="companyTitleId" boundary="viewport" placement="bottom">
          {{ selectedCompany.name }}
        </b-tooltip>
        <h5 :id="companyTitleId" v-if="selectedCompany" ref="companyTitle" class="m-0 text-center cursor-pointer text-truncate" @click="$router.push({ name: 'companies' })">
          {{ selectedCompany.name }}
        </h5>
      </template>
      <template #rightSide>
        <div class="py-50">
          <branch-select :reduce="(option) => option._id" :company="companyId" disable-label update-store no-gap select-if-single-element-exist />
        </div>
      </template>
    </company-tabs>
  </div>
</template>

<script>
import CompanyInfoTabs from "./info/CompanyInfoTabs.vue";
import ISGTabs from "./isg/ISGTabs.vue";
import CompanyVisitList from "./isg/visits/CompanyVisitList.vue";
import companyTabs from "./companyTabs.vue";
import CompanyFinanceTabs from "./finance/CompanyFinanceTabs.vue";
import { v4 as uuidv4 } from "uuid";
export default {
  components: { companyTabs },
  data() {
    const companyTitleId = uuidv4();
    return {
      companyTitleId,
      tabs: [
        { title: "Firma Bilgileri", component: CompanyInfoTabs, resource: "work-safety" },
        { title: "İSG Takip", component: ISGTabs, resource: "work-safety" },
        { title: "Ziyaretler", component: CompanyVisitList, resource: "work-safety" },
        { title: "CRM", resource: "work-safety" },
        { title: "Mevzuat Uyumu", resource: "work-safety" },
        { title: "Finans", component: CompanyFinanceTabs, resource: "finance-contacts" },
        { title: "Raporlar", resource: "work-safety" },
      ],
      resizeObserver: null,
      isTruncated: false,
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    companyId() {
      return this.selectedCompany?._id;
    },
  },
  methods: {
    createResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          this.checkTruncation();
        }
      });
      this.resizeObserver.observe(this.$refs.companyTitle);
    },
    checkTruncation() {
      const el = this.$refs.companyTitle;
      if (el?.offsetWidth < el?.scrollWidth) {
        this.isTruncated = true;
      } else {
        this.isTruncated = false;
      }
    },
  },

  mounted() {
    this.$store.commit("setSelectedBranch", null);
    this.createResizeObserver();
  },
  destroyed() {
    if (this.resizeObserver) this.resizeObserver.disconnect();

    if (this.$route.name !== "company-details") {
      this.$store.commit("setSelectedCompany", null);
      this.$store.commit("setSelectedBranch", null);
    }
  },
};
</script>

<style></style>
