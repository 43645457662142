<template>
  <b-custom-tabs id="employeeDetailsModalTabs" :tabs="tabs" lazy />
</template>

<script>
import EmployeeInfo from "./EmployeeInfo.vue";
import EmployeeTrainingList from "./EmployeeTrainingList.vue";
import EmployeeFileList from "./EmployeeFiles/EmployeeFilesList.vue";

export default {
  data() {
    return {
      tabs: [
        { title: "Genel Bilgiler", component: EmployeeInfo },
        { title: "Eğitimler", component: EmployeeTrainingList },
        { title: "Sağlık Tetkikleri" },
        { title: "Belgeler", component: EmployeeFileList },
        { title: "Raporlar" },
        { title: "Notlar" },
      ],
    };
  },
};
</script>

<style></style>
