<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getData" :items="documents" :fields="fields" :total-rows="total" searchable>
      <template #modalbutton>
        <app-button v-if="!isCompanyArchived" class="flex-1-md" text="Dosya Yükle" size="md" icon="UploadIcon" @click="showForm" />
      </template>
      <template #customName="{ item }">
        <span v-if="item.docTemplate && item.docTemplate.name"> {{ item.docTemplate.name }}</span>
        <span v-else-if="item.customName"> {{ item.customName }} </span>
        <span v-else> - </span>
      </template>
      <template #addedBy="{ item }">
        <span v-if="item.addedBy && item.addedBy.user && item.addedBy.user.fullname && item.addedBy.user.role">
          <app-user-info variant="secondary" :title="item.addedBy.user.fullname" :alt_title="item.addedBy.user.role.value" />
        </span>
        <span v-else> - </span>
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="getFile(item)">
            <feather-icon class="mr-50" icon="EyeIcon" />
            <span>Görüntüle</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteFile(item._id)" v-if="!isCompanyArchived">
            <feather-icon class="mr-50" icon="TrashIcon" />
            <span>Kaldır</span>
          </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import FileForm from "../plans/FileForm.vue";

export default {
  components: { FileForm },
  data() {
    return {
      filters: {},
      FileForm,
    };
  },
  computed: {
    fields() {
      return [
        ...(this.selectedBranch ? [] : [{ key: "branch.name", label: "Sicil" }]),
        { key: "customName", label: "Dosya Adı" },
        { key: "fileGroup.value", label: "Dosya Türü" },
        { key: "addedBy", label: "Yükleyen" },
        { key: "createdAt", label: "Yüklenme Tarihi", formatDateTime: true },
        { key: "actions", label: "Eylemler" },
      ];
    },
    selectedCompany() {
      return this.$store.getters?.selectedCompany;
    },
    selectedBranch() {
      return this.$store.getters?.selectedBranch;
    },
    documents() {
      return this.$store.getters.documents?.data;
    },
    total() {
      return this.$store.getters.documents?.total;
    },
    isCompanyArchived() {
      return this.$store.getters.selectedCompany?.isArchived;
    },
  },
  methods: {
    showForm() {
      this.$showAppSidebar("Dosya Yükle", FileForm, {
        fileGroupKey: "document",
        query: {
          ...this.filters,
          "fileGroup.group": "document",
          company: this.selectedCompany?._id,
          branch: this.selectedBranch ?? undefined,
        },
      });
    },
    getData() {
      this.$store.dispatch("getWsFiles", {
        query: {
          ...this.filters,
          "fileGroup.group": "document",
          company: this.selectedCompany?._id,
          branch: this.selectedBranch ?? undefined,
        },
        actionType: "setDocuments",
      });
    },
    deleteFile(id) {
      this.$confirm({ message: "Dosya silinecek emin misiniz?" }, () => {
        this.$axios
          .delete(`/company/ws-files/${id}`, { loading: "table" })
          .then(() => {
            this.getData();
            this.$emitter.$emit("Notification", {
              variant: "success",
              title: "İşlem Başarılı",
              message: "Dosya sistemden başarıyla silindi.",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getFile(item) {
      this.$axios
        .get(`/company/ws-files/${item?._id}`, { loading: "table", responseType: "blob" })
        .then((result) => {
          const blob = new Blob([result.data], { type: result.headers["content-type"] });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        })
        .catch((err) => {
          console.log(err);
          this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger" });
        });
    },
  },
  watch: {
    selectedBranch() {
      this.getData();
    },
  },
  destroyed() {
    this.$store.commit("setDocuments", []);
  },
};
</script>
