<template>
  <app-overlay loading-variable="modalOverlay">
    <searchable-table :fields="fields" class="border mb-1" :items="supportedEmployees">
      <template #modalbutton>
        <app-button v-if="!isCompanyArchived" text="Çalışan Seç" icon="PlusIcon" size="md" @click="showForm" />
      </template>
      <template #actions="{ item }">
        <app-icon-button v-if="!isCompanyArchived" variant="gradient-danger" icon="TrashIcon" @click="deleteSupportEmployee(item)" />
        <span v-else> - </span>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import SupportedEmployeeForm from "./SupportedEmployeeForm.vue";

export default {
  props: {
    employeeType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "employee.fullname", label: "Ad Soyad", sortable: true },
        { key: "branch.name", label: "Sicil", sortable: true },
        {
          key: "createdAt",
          label: "Eklenme Tarihi",
          sortable: true,
          formatDateTime: true,
        },
        { key: "actions", label: "İşlemler", sortable: false },
      ],
    };
  },
  computed: {
    supportedEmployees() {
      return this.$store.getters.supportTeams(this.employeeType);
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    isCompanyArchived() {
      return this.$store.getters.selectedCompany?.isArchived;
    },
  },
  mounted() {
    this.getSupportTeams();
  },
  destroyed() {
    this.$store.commit("setSupportTeams", []);
  },
  methods: {
    showForm() {
      this.$showAppModalSidebar("Çalışan Seç", SupportedEmployeeForm, { employeeType: this.employeeType });
    },
    getSupportTeams() {
      if (this.selectedCompany) {
        this.$axios
          .get("/employees/support-team/list", { params: { company: this.selectedCompany }, loading: "modal" })
          .then((res) => {
            this.$store.commit("setSupportTeams", res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteSupportEmployee(item) {
      this.$confirm({ message: "Çalışanı silmek istediğinize emin misiniz?" }, () => {
        this.$axios
          .delete(`/employees/support-team/${item?._id}`, { loading: "table" })
          .then(() => {
            this.$emitter.$emit("Notification", {
              title: "Çalışan Silindi.",
              variant: "success",
            });
            this.$store.commit("deleteSupportTeamEmployee", item?._id);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style></style>
