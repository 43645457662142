<template>
  <app-overlay loading-variable="sidebarOverlay">
    <small>
      - Sadece PDF, JPG ve PNG dosya formatı yüklenebilir. <br />
      - En fazla 5 MB boyutunda dosya yükleyebilirsiniz.
    </small>
    <hr />
    <validation-observer ref="fileinput">
      <app-input v-model="customName" name="Dosya Adı" label="Dosya Adı:" placeholder="Dosya Adı..." rules="required" />
      <app-file-input v-model="file" label="Dosya:" :accept="$acceptMimes(['jpg', 'jpeg', 'png', 'pdf'])" requires />
    </validation-observer>
    <hr />
    <app-button icon="UploadIcon" size="md" text="Yükle" block @click="uploadFile" />
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data: () => ({
    file: null,
    customName: null,
  }),
  computed: {
    employeeId() {
      return this.$store.getters?.appModalItem?._id;
    },
  },
  methods: {
    setData() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("customName", this.customName);
      formData.append("employee", this.employeeId);
      return formData;
    },
    uploadFile() {
      this.$validate(this.$refs.fileinput, () => {
        this.$store.dispatch("uploadEmployeeFile", { data: this.setData() });
      });
    },
  },
};
</script>

<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Dosya Seç";
}
</style>
