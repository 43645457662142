<template>
  <supported-employees-table employee-type="employeeRepresentative" />
</template>

<script>
import SupportedEmployeesTable from './supportedEmployees/SupportedEmployeesTable.vue'

export default {
  components: { SupportedEmployeesTable },
}
</script>

<style></style>
