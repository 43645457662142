<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getFiles" class="border" :items="items" :total-rows="total" :fields="tableFields" searchable>
      <template #modalbutton>
        <app-button v-if="!isCompanyArchived" text="Dosya Yükle" icon="UploadIcon" size="md" @click="$showAppSidebar('Dosya Yükle', FileForm)" />
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="downloadFile(item._id)">
            <feather-icon icon="DownloadIcon" class="mr-50" />
            İndir
          </b-dropdown-item>
          <b-dropdown-item @click="deleteFile(item._id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            Sil
          </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import { baseURL } from "@/utils/appAxios";
import FileForm from "./FileForm.vue";

export default {
  data: () => ({
    filters: {},
    tableFields: [
      { key: "customName", label: "Dosya Adı" },
      { key: "fileType", label: "Dosya Tipi" },
      { key: "createdAt", label: "Yükleme Tarihi", formatDateTime: true },
      { key: "actions", label: "İşlemler" },
    ],
    FileForm,
    baseURL,
  }),
  computed: {
    selectedCompany() {
      return this.$store.getters?.selectedCompany?._id;
    },
    isCompanyArchived() {
      return this.$store.getters.selectedCompany?.isArchived;
    },
    items() {
      return this.$store.getters?.companyFiles?.data ?? [];
    },
    total() {
      return this.$store.getters?.companyFiles?.total ?? 0;
    },
  },
  methods: {
    getFiles() {
      this.selectedCompany &&
        this.$axios
          .get("/company-file", {
            params: { ...this.filters, company: this.selectedCompany },
            loading: "table",
          })
          .then((res) => {
            this.$store.commit("setCompanyFiles", res.data);
          })
          .catch((err) => {
            console.log(err);
          });
    },
    downloadFile(id) {
      this.$axios
        .get(`/company-file/${id}`, { loading: "table", responseType: "blob" })
        .then((result) => {
          const blob = new Blob([result.data], {
            type: result.headers["content-type"],
          });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata Oluştu",
            message: err?.data?.message ?? null,
          });
        });
    },
    deleteFile(id) {
      this.$confirm({ message: "Dosya silinecek emin misiniz?" }, () => {
        this.$axios
          .delete(`/company-file/${id}`, { loading: "table" })
          .then(() => {
            this.getFiles();
            this.$emitter.$emit("Notification", {
              variant: "success",
              title: "İşlem Başarılı",
              message: "Dosya sistemden başarıyla silindi.",
            });
          })
          .catch((error) => {
            console.log(error);
            this.$emitter.$emit("Notification", {
              variant: "success",
              title: "Hata Oluştu",
            });
          });
      });
    },
  },
  destroyed() {
    this.$store.commit("setCompanyFiles", []);
  },
};
</script>

<style></style>
