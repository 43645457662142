<template>
  <div v-if="!isCompanyArchived">
    <b-alert v-for="(item, key) in branches" :key="key" :variant="assignmentCheck(item._id).variant" show>
      <div class="alert-body">
        <feather-icon :icon="assignmentCheck(item._id).icon" class="mr-50" />
        {{ item.name }}
        {{ assignmentCheck(item._id).message }}
      </div>
    </b-alert>
  </div>
</template>

<script>
export default {
  props: {
    physicianRole: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      branchItems: [],
    };
  },
  computed: {
    assignments() {
      return this.$store.getters.assignments.filter((item) => item?.physician?.role === this.physicianRole);
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    branches() {
      return this.selectedBranch ? this.branchItems.filter((item) => item._id === this.selectedBranch) : this.branchItems;
    },
    isCompanyArchived() {
      return this.$store.getters.selectedCompany?.isArchived;
    },
    assignmentCheck() {
      return (branch) => {
        const messages = {
          success: "ataması tamamlanmıştır.",
          warning: "ataması onay beklemektedir.",
          danger: "ataması yapılmamıştır.",
        };
        const icon = {
          success: "CheckCircleIcon",
          warning: "ClockIcon",
          danger: "AlertTriangleIcon",
        };
        const assignment = this.assignments.find((item) => item.branch._id === branch && item.isAssignmentActive);
        const confirmCheck = assignment?.physicianConfirm === "onayda" || assignment?.companyConfirm === "onayda";
        const variant = !assignment ? "danger" : confirmCheck ? "warning" : "success";
        return {
          variant,
          message: messages[variant],
          icon: icon[variant],
        };
      };
    },
  },
  mounted() {
    this.getBranches();
  },
  methods: {
    getBranches() {
      this.selectedCompany &&
        this.$store
          .dispatch("getBranchOptions", this.selectedCompany)
          .then((branches) => (this.branchItems = branches))
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>

<style></style>
