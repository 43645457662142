<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="fileinput">
      <branch-select v-model="branch" label="Sicil:" :company="selectedCompany" :reduce="(option) => option._id" required />
      <app-select-input
        v-model="fileGroup"
        name="Dosya Türü"
        label="Dosya Türü:"
        placeholder="Dosya Türü Seçiniz..."
        select_label="value"
        :options="fileGroupOptions"
        rules="required"
      />
      <hr />
      <div v-if="fileGroup">
        <b-form-radio-group
          v-model="uploadType"
          class="d-flex justify-content-center align-items-center flex-wrap gap-5"
          :options="radioOptions"
          value-field="value"
          text-field="text"
          @change="handleRadioChange"
        />
        <hr />
        <doc-temp-options v-if="uploadType === 'docTemplate'" v-model="docTemplate" :file-group-value="fileGroup.value" />
        <div v-if="uploadType === 'upload'">
          <div class="mb-1">
            <app-file-input v-model="file" label="Dosya:" :accept="$acceptMimes(['csv', 'xls', 'xlsx', 'doc', 'docx', 'pdf'])" :size-m-b-limit="5" requires />
          </div>
          <app-input v-model="customName" name="Dosya Adı" label="Dosya Adı:" placeholder="Dosya Adı..." rules="required|max:100" />
          <hr />
          <small>
            - Sadece Excel, Word veya PDF dosya formatı yüklenebilir. <br />
            - En fazla 5 MB boyutunda dosya yükleyebilirsiniz.
          </small>
        </div>
        <hr />
        <app-button
          v-if="uploadType === 'docTemplate' && !validityIncludeFields.includes(fileGroup.value)"
          @click="downloadFile"
          icon="DownloadIcon"
          icon-position="left"
          size="md"
          text="İndir"
          block
        />
        <app-button v-else icon="UploadIcon" icon-position="left" size="md" text="Yükle" block @click="checkValidityFileExist" />
        <hr />
      </div>
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import DocTempOptions from "./DocTempOptions.vue";

export default {
  components: { ValidationObserver, DocTempOptions },

  data() {
    return {
      validityIncludeFields: ["Risk Değerlendirme Raporu", "Acil Durum Eylem Planı"],
      docTemplate: null,
      file: null,
      branch: null,
      customName: null,
      fileGroup: null,
      uploadType: "docTemplate",
      fileGroups: [
        { group: "plan", value: "Risk Değerlendirme Raporu" },
        { group: "plan", value: "Acil Durum Eylem Planı" },
        { group: "plan", value: "Yıllık Çalışma Planı" },
        { group: "plan", value: "Yıllık Değerlendirme Planı" },
        { group: "document", value: "İSG Evrağı" },
        { group: "document", value: "Check List" },
        { group: "document", value: "Talimat" },
        { group: "document", value: "Form" },
        { group: "document", value: "Rapor" },
      ],
    };
  },
  computed: {
    fileGroupKey() {
      return this.$store.getters.appSidebarItem?.fileGroupKey;
    },
    radioOptions() {
      return [
        ...(this.validityIncludeFields.includes(this?.fileGroup?.value) ? [{ text: "Kütüphaneden Seç", value: "docTemplate" }] : [{ text: "Şablon İndir", value: "docTemplate" }]),
        { text: "Bilgisayardan Yükle", value: "upload" },
      ];
    },
    query() {
      return this.$store.getters.appSidebarItem?.query;
    },
    fileGroupOptions() {
      return this.fileGroups.filter((item) => item.group === this.fileGroupKey);
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters?.selectedBranch ?? null;
    },
  },
  mounted() {
    this.branch = this.selectedBranch;
  },
  methods: {
    setData() {
      if (this.docTemplate) {
        return {
          company: this.selectedCompany,
          branch: this.branch,
          docTemplate: this.docTemplate,
          fileGroup: this.fileGroup,
        };
      }
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("customName", this.customName);
      formData.append("company", this.selectedCompany);
      formData.append("branch", this.branch);
      formData.append("fileGroup", JSON.stringify(this.fileGroup));
      return formData;
    },
    downloadFile() {
      this.$validate(this.$refs.fileinput, () => {
        if (this.docTemplate)
          this.$axios
            .get(`/doc-templates/${this.docTemplate}`, { loading: "sidebar", responseType: "blob" })
            .then((result) => {
              const blob = new Blob([result.data], { type: result.headers["content-type"] });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = result.headers["content-disposition"].split("filename=")[1];
              link.click();
            })
            .catch((err) => {
              console.log(err);
              this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger" });
            });
      });
    },
    handleRadioChange() {
      this.docTemplate = null;
      this.file = null;
      this.customName = null;
    },
    checkValidityFileExist() {
      this.$validate(this.$refs.fileinput, () => {
        if (this.fileGroup?.group === "document" || !this.validityIncludeFields.includes(this.fileGroup?.value)) {
          this.uploadFile();
        } else {
          this.$axios
            .get("/company/ws-files/check-validity", {
              params: {
                company: this.selectedCompany,
                branch: this.branch,
                "fileGroup.value": this.fileGroup.value,
              },
              loading: "sidebar",
            })
            .then((result) => {
              result.status === 201
                ? this.$confirm({ message: "Yürürlükte olan mevcut dosyanız sonlandırılacaktır onaylıyor musunuz? " }, () => {
                    this.uploadFile();
                  })
                : this.uploadFile();
            })
            .catch((err) => {
              this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message });
            });
        }
      });
    },
    uploadFile() {
      this.$validate(this.$refs.fileinput, () => {
        this.$axios
          .post("/company/ws-files", this.setData(), { loading: "sidebar" })
          .then(() => {
            this.$store.dispatch("getWsFiles", { query: this.query, actionType: this.fileGroupKey === "plan" ? "setPlans" : "setDocuments" });
            this.$store.commit("cleanAppSidebar");
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı", message: "Dosya sisteme başarıyla yüklendi." });
          })
          .catch((error) => {
            console.log(error);
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: error?.data?.message });
          });
      });
    },
  },
};
</script>

<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Dosya Seç";
}
</style>
