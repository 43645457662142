<template>
  <app-overlay loading-variable="modalOverlay">
    <db-searchable-table v-model="filters" @changed="getData" class="border mb-50" :items="trainings" :total-rows="totalCount" :fields="fields" searchable>
      <template #instructor="data">
        <app-user-info v-if="data.item.instructor" :title="data.item.instructor.auth.fullname" :alt_title="data.item.instructor.auth.role.value" icon="" variant="secondary" />
        <span v-else> - </span>
      </template>
      <template #actions>
        <app-dropdown v-if="windowWidth < 480">
          <b-dropdown-item>
            <feather-icon icon="FileTextIcon" />
            <span class="ml-50">Dijital Sertifika</span>
          </b-dropdown-item>
          <b-dropdown-item>
            <feather-icon icon="FileTextIcon" />
            <span class="ml-50">Sertifika Aslı</span>
          </b-dropdown-item>
        </app-dropdown>
        <div v-else class="d-flex gap-3">
          <app-button text="Dijital Sertifika" />
          <app-button text="Sertifika Aslı" />
        </div>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      filters: {},
    };
  },
  computed: {
    trainings() {
      return this.$store.getters.employeeTrainings?.data || [];
    },
    totalCount() {
      return this.$store.getters.employeeTrainings?.total || 0;
    },
    employee() {
      return this.$store.getters.appModalItem;
    },
    fields() {
      return [
        { key: "subject", label: "Konu" },
        { key: "trainingDate", label: "Planlama Tarihi", formatDate: true },
        { key: "dateOfStartTraining", label: "Eğitim Tarihi", formatDate: true },
        { key: "instructor", label: "Eğitim Veren" },
        { key: "actions", label: "İşlemler" },
      ];
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  methods: {
    getData() {
      this.$store.dispatch("getTrainings", {
        actionType: "setEmployeeTrainings",
        query: {
          ...this.filters,
          searchFields: this.filters.search ? JSON.stringify(["subject"]) : null,
          "employees.employee": this.employee._id,
        },
        loading: "modal",
      });
    },
  },
  destroyed() {
    this.$store.commit("setEmployeeTrainings", []);
  },
};
</script>
