<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getData" :items="assignments" :fields="fields" :total-rows="total" searchable>
      <template #modalbutton>
        <app-button v-if="!isCompanyArchived" class="flex-1-md" icon="PlusIcon" text="Atama Yap" size="md" @click="showAssignmentForm" />
      </template>
      <template #physician="{ item }">
        <app-user-info v-if="item.physician" :title="item.physician.auth.fullname" icon="" variant="secondary" />
        <span v-else> - </span>
      </template>
      <template #physicianConfirm="{ item }">
        <b-badge :variant="$variants[item.physicianConfirm]">
          {{ $capitalize(item.physicianConfirm) }}
        </b-badge>
      </template>
      <template #companyConfirm="{ item }">
        <b-badge :variant="$variants[item.companyConfirm]">
          {{ $capitalize(item.companyConfirm) }}
        </b-badge>
      </template>
      <template #isAssignmentActive="{ item }">
        <b-badge :variant="item.isAssignmentActive ? 'success' : 'danger'">
          {{ item.isAssignmentActive ? "Aktif" : "Pasif" }}
        </b-badge>
      </template>
      <template #actions="{ item }">
        <app-icon-button v-if="item.isAssignmentActive" v-b-tooltip.hover.bottom="'Atamayı Kaldır'" variant="gradient-danger" icon="XIcon" @click="deactiveAssignment(item)" />
        <span v-else> - </span>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import AssignmentForm from "./AssignmentForm.vue";

export default {
  props: {
    physicianRole: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filters: {},
      getters: {
        wsExpert: "wsExpertAssignments",
        physician: "physicianAssignments",
      },
      mutations: {
        wsExpert: "setWsExpertAssignments",
        physician: "setPhysicianAssignments",
      },
    };
  },
  computed: {
    assignments() {
      return this.$store.getters[this.getters[this.physicianRole]]?.data ?? [];
    },
    total() {
      return this.$store.getters[this.getters[this.physicianRole]]?.total ?? 0;
    },
    isCompanyArchived() {
      return this.$store.getters.selectedCompany?.isArchived;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
    fields() {
      return this.windowWidth < 767
        ? [
            {
              key: "physician",
              label: "Görevli Personel",
            },
          ]
        : [
            ...(this.selectedBranch ? [] : [{ key: "branch.name", label: "Sicil" }]),
            {
              key: "physician",
              label: "Görevli Personel",
            },
            { key: "physicianConfirm", label: "Görevli Onayı" },
            { key: "companyConfirm", label: "Firma Onayı" },
            {
              key: "createdAt",
              label: "Atama Tarihi",

              formatDateTime: true,
            },
            {
              key: "passivizationDate",
              label: "Kaldırılma Tarihi",

              formatDateTime: true,
            },
            { key: "actions", label: "İşlemler" },
          ];
    },
  },
  methods: {
    getData() {
      this.$axios
        .get("/rest-assignments/paginated-assignments", {
          params: {
            ...this.filters,
            sortBy: this.filters?.sortBy ?? "isAssignmentActive",
            company: this.selectedCompany,
            branch: this.selectedBranch,
            roleKey: this.physicianRole,
            populates: JSON.stringify(["physician", ...(this.selectedBranch ? [] : ["branch"])]),
          },
          loading: "table",
        })
        .then((result) => {
          /* result.data.data = result?.data?.data?.map((item) => ({ ...item, _rowVariant: item?.isAssignmentActive ? "" : "" })); */
          this.$store.commit(this.mutations[this.physicianRole], result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showAssignmentForm() {
      this.$showAppSidebar("Atama Yap", AssignmentForm, { physicianRole: this.physicianRole, callback: this.getData });
    },
    deactiveAssignment(item) {
      this.$confirm({ message: "Atamayı kaldırmak istediğinize emin misiniz?" }, () => {
        this.$store.dispatch("deactiveAssignment", { id: item?._id, callback: this.getData });
      });
    },
  },
  watch: {
    selectedBranch() {
      this.getData();
    },
  },
  destroyed() {
    this.$store.commit(this.mutations[this.physicianRole], { data: [], total: 0 });
  },
};
</script>

<style></style>
