<template>
  <custom-collapse :collapses="collapses" lazy>
    <template v-for="collapse in collapses" :slot="collapse.key">
      <!--  <assignment-status-bars :key="key" :physician-role="collapse.key" /> -->
      <assignment-table v-if="collapse" :key="collapse.key" :physician-role="collapse.key" />
    </template>
  </custom-collapse>
</template>

<script>
import AssignmentStatusBars from "./AssignmentStatusBars.vue";
import AssignmentTable from "./AssignmentTable.vue";

export default {
  components: { AssignmentStatusBars, AssignmentTable },
  data() {
    return {
      collapses: [
        { key: "wsExpert", title: "İş Güvenliği Uzmanı" },
        { key: "physician", title: "İşyeri Hekimi" },
      ],
    };
  },
};
</script>

<style></style>
