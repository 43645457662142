<template>
  <app-overlay>
    <b-card body-class="p-1" class="border rounded mb-50">
      <b-row>
        <b-col lg="6">
          <app-detail-table :fields="firstField" :data="company">
            <template #name="{ name }">
              {{ $capitalize(name) }}
            </template>
            <template #contractDate="data">
              {{ $FormattedDate(data.contractDate) }}
            </template>
          </app-detail-table>
          <hr class="mt-0" />
        </b-col>
        <b-col lg="6">
          <app-detail-table :fields="secondField" :data="company">
            <template #contractDate="data">
              {{ $FormattedDate(data.contractDate) }}
            </template>
          </app-detail-table>
          <hr class="mt-0" />
        </b-col>
        <div class="d-flex gap-5 px-1">
          <app-button v-if="!isCompanyArchived" size="md" icon="EditIcon" text="Bilgileri Düzenle" @click="$showAppSidebar('Bilgileri Güncelle', CompanyUpdateForm)" />
          <app-button @click="archiveAction('archive')" v-if="!isCompanyArchived" size="md" icon="ArchiveIcon" text="Arşivle" />
          <app-button @click="archiveAction('revoke-archive')" v-if="isCompanyArchived" size="md" icon="ArchiveIcon" text="Arşivi Geri Al" />
        </div>
      </b-row>
    </b-card>
  </app-overlay>
</template>

<script>
import CompanyUpdateForm from "./CompanyUpdateForm.vue";

export default {
  data: () => ({
    CompanyUpdateForm,
    firstField: [
      { key: "name", label: "Firma Unvanı", icon: "BriefcaseIcon" },
      { key: "naceCode", label: "Nace Kodu", icon: "FileIcon" },
      {
        key: "sector.value",
        label: "Sektör",
        icon: "BriefcaseIcon",
        capitalize: true,
      },
      { key: "employeeCount", label: "Toplam Çalışanlar", icon: "UsersIcon" },
    ],
    secondField: [
      { key: "contractNumber", label: "Sözleşme No", icon: "FileTextIcon" },
      { key: "contractDate", label: "Sözleşme Tarihi", icon: "CalendarIcon" },
      { key: "taxNo", label: "Vergi Numarası", icon: "FileIcon" },
      { key: "taxOffice", label: "Vergi Dairesi", icon: "MapPinIcon" },
    ],
  }),
  computed: {
    company() {
      return this.$store.getters?.selectedCompany;
    },
    isCompanyArchived() {
      return this.$store.getters.selectedCompany?.isArchived;
    },
  },
  methods: {
    archiveAction(type) {
      const messages = {
        archive: "Arşivlemek istediğinize emin misiniz?",
        "revoke-archive": "Arşivden geri almak istediğinize emin misiniz?",
      };
      this.$confirm({ message: messages[type] }, () => {
        this.$axios
          .patch(`/company/${this?.company?._id}/${type}`, null, { loading: "table" })
          .then(() => {
            this.$store.commit("updateArchived");
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı" });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
          });
      });
    },
  },
};
</script>

<style></style>
