<template>
  <b-row>
    <b-col md="6">
      <b-card body-class="p-50" class="mb-1 border">
        <app-detail-table :data="employee" :fields="fields"> </app-detail-table>
        <hr class="mt-0" />
        <div class="d-flex flex-wrap gap-10">
          <app-button @click="editEmployee" class="flex-1" text="Düzenle" icon="EditIcon" size="md" />
          <app-button @click="archiveEmployee" class="flex-1" text="Arşivle" icon="TrashIcon" size="md" />
        </div>
      </b-card>
    </b-col>
    <b-col md="6">
      <b-card body-class="p-50" class="mb-1 border">
        <app-detail-table :data="employee" :fields="infoFileds">
          <template #isArchived="data">
            <b-badge :variant="data.isArchived ? 'secondary' : 'success'">
              {{ data.isArchived ? "Arşivlendi" : "Aktif" }}
            </b-badge>
          </template>
        </app-detail-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import EmployeeForm from "../EmployeeForm.vue";

export default {
  data() {
    return {
      fields: [
        { key: "fullname", label: "İsim", icon: "UserIcon" },
        { key: "tcNo", label: "TC Kimlik No", icon: "UserIcon" },
        { key: "phone", label: "Telefon", icon: "PhoneIcon" },
        { key: "email", label: "E-Posta", icon: "MailIcon" },
        { key: "duty", label: "Görevi", icon: "BriefcaseIcon" },
      ],
      infoFileds: [
        { key: "dateOfStartWork", label: "İşe Giriş Tarihi", icon: "CalendarIcon", formatDate: true },
        { key: "dateOfEximination", label: "İşe Giriş Muayene Tarihi", mdiIcon: "Stethoscope", formatDate: true },
        { key: "dateOfWorkSafetyTraining", label: "İSG Eğitim Tarihi", mdiIcon: "HumanMaleBoardPoll", formatDate: true },
        { key: "isArchived", label: "Durum", icon: "ArchiveIcon" },
      ],
    };
  },
  computed: {
    employee() {
      return this.$store.getters.appModalItem || {};
    },
  },
  methods: {
    archiveEmployee() {
      this.$confirm({ message: "Çalışanı arşivlemek istediğinize emin misiniz?" }, () => this.$store.dispatch("archiveEmployee", this.employee?._id));
    },
    editEmployee() {
      this.$showAppModalSidebar("Çalışan Düzenle", EmployeeForm);
    },
  },
};
</script>

<style></style>
