<template>
  <div>
    <b-card class="m-0" body-class="p-0 px-1">
      <b-row class="align-items-center">
        <b-col class="company-tab-container" xl="8" lg="6">
          <div :class="{ 'd-none': windowWidth > 479 }" class="m-50">
            <b-dropdown v-if="tabs[currentTab]" :text="tabs[currentTab].title" variant="gradient-primary" center menu-class="w-100" block>
              <template v-for="(item, key) in tabs">
                <b-dropdown-item v-if="$can(item.action ? item.action : 'read', item.resource)" @click="routeCheck(null, item, key)" :active="key === currentTab" :key="key">{{
                  item.title
                }}</b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <ul :id="id" ref="tabScroll" class="nav nav-pills scrollable-tabs mb-0 py-50" :class="{ 'd-none': windowWidth < 479, 'padding-gap': showArrows }">
            <template v-for="(item, index) in tabs">
              <li v-if="$can(item.action ? item.action : 'read', item.resource)" :key="index" class="nav-item">
                <a :id="'custom-tab-button' + index" class="nav-link" :class="{ active: currentTab === index }" @click="routeCheck($event, item, index)">{{ item.title }}</a>
              </li>
            </template>
          </ul>
          <Transition name="fade">
            <div v-show="showArrows" ref="leftArrow" class="company-custom-tab-arrow company-tab-arrow-left-custom">
              <app-icon-button icon="ChevronLeftIcon" variant="gradient-primary" @click="scrollTabs('left')" />
            </div>
          </Transition>
          <Transition name="fade">
            <div v-show="showArrows" ref="rightArrow" class="company-custom-tab-arrow company-tab-arrow-right-custom">
              <app-icon-button icon="ChevronRightIcon" variant="gradient-primary" @click="scrollTabs('right')" />
            </div>
          </Transition>
        </b-col>
        <b-col class="py-50 border-right border-left" xl="2" lg="3">
          <slot name="leftSide" v-bind="{ currentTab }" />
        </b-col>

        <b-col xl="2" lg="3">
          <slot name="rightSide" v-bind="{ currentTab }" />
        </b-col>
      </b-row>
    </b-card>
    <b-tabs v-model="currentTab" :nav-class="{ 'm-0': noBottomGap }" pills :lazy="lazy">
      <template v-for="(item, key) in tabs">
        <b-tab
          v-if="true"
          v-show="$can(item.action ? item.action : 'read', item.resource)"
          :key="key"
          title-item-class="d-none"
          :title="item.title"
          :lazy="item.lazy ? item.lazy : lazyOnce ? !mountedTabs.includes(key) : false"
        >
          <slot v-if="!item.component" :name="item.key" />
          <component :is="item.component" v-else />
        </b-tab>
      </template>
    </b-tabs>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },

    tabIndex: {
      type: Number,
      default: 0,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    lazyOnce: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    noBottomGap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showArrows: false,
      mountedTabs: [],
      currentTab: 0,
    };
  },
  computed: {
    isVerticalMenuCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  watch: {
    isVerticalMenuCollapsed() {
      setTimeout(() => {
        this.checkOverflow();
      }, 300);
    },
  },
  created() {
    window.addEventListener("resize", this.checkOverflow);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkOverflow);
  },
  mounted() {
    this.$nextTick(() => {
      this.mountedTabs.push(this.currentTab);
      this.checkOverflow();
      setTimeout(() => {
        const el = document.getElementById(`custom-tab-button${this.currentTab}`);
        el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }, 100);
    });
  },
  methods: {
    routeCheck(event, item, key) {
      this.currentTab = key;
      if (this.lazyOnce) {
        if (!this.mountedTabs.includes(key)) {
          this.mountedTabs.push(key);
        }
      }
      item?.route && item?.route !== this.$route.name && this.$router.push({ name: item.route });
      event &&
        setTimeout(() => {
          event?.target.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        }, 100);
    },
    scrollTabs(direction) {
      const checkIDElement = document.getElementById(this.id);
      const tabNav = checkIDElement || document.querySelector(".scrollable-tabs");
      const scrollAmount = direction === "left" ? -200 : 200;
      tabNav.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    },
    checkOverflow() {
      const tabScrollContainer = this.$refs.tabScroll;
      const checkIDElement = document.getElementById(this.id);
      const tabNav = checkIDElement || document.querySelector(".scrollable-tabs");
      this.showArrows = tabScrollContainer.clientWidth < tabNav.scrollWidth;
    },
  },
};
</script>

<style>
/* .tab-gap {
  padding: 0.75rem 0 !important;
} */
.company-tab-container {
  position: relative;
}

.company-custom-tab-arrow {
  position: absolute;
  top: 0;
  height: 100%;
  transition: linear 0.2s;
  padding: 10px 0;
}

.company-tab-arrow-left-custom {
  left: 1%;
}

.company-tab-arrow-right-custom {
  right: 1%;
}
/* .padding-gap {
  padding: 0 2rem !important;
} */
</style>
