<template>
  <app-overlay>
    <db-searchable-table
      v-model="filters"
      @changed="getData"
      :items="branches"
      :fields="fields"
      :hover="!isCompanyArchived"
      :total-rows="total"
      @row-clicked="showSidebar"
      searchable
    >
      <template #modalbutton>
        <app-button v-if="!isCompanyArchived" class="flex-1-md" text="Sicil Ekle" icon="PlusIcon" size="md" @click="$showAppSidebar('Sicil Ekle', BranchForm)" />
      </template>
      <template #confirmation="{ item }">
        <b-badge v-if="item.confirmation" :variant="item.confirmation.status === 'beklemede' ? 'warning' : item.confirmation.status === 'reddedildi' ? 'danger' : 'success'">
          {{ $capitalize(item.confirmation.status) }}
        </b-badge>
        <span v-else> - </span>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import BranchForm from "./BranchForm.vue";

export default {
  data() {
    return {
      filters: {},
      fields: [
        { key: "name", label: "Sicil Adı" },
        { key: "recordNo", label: "Sicil No" },
        { key: "riskState.value", label: "Tehlike Sınıfı" },
        { key: "confirmation", label: "Onay Durumu" },
      ],
      BranchForm,
    };
  },
  computed: {
    branches() {
      return this.$store.getters.branches?.data ?? [];
    },
    total() {
      return this.$store.getters.branches?.total ?? 0;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    isCompanyArchived() {
      return this.$store.getters.selectedCompany?.isArchived;
    },
  },
  methods: {
    getData() {
      this.selectedCompany && this.$store.dispatch("getCompanyBranches", { ...this.filters, company: this.selectedCompany?._id, populates: JSON.stringify(["riskState"]) });
    },
    showSidebar(item) {
      if (!this.isCompanyArchived) {
        this.$axios
          .get(`/company/branches/${item._id}`, { loading: "table" })
          .then((result) => {
            this.$showAppSidebar("Sicil Düzenle", BranchForm, result.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  destroyed() {
    this.$store.commit("setBranches", { data: [], total: 0 });
  },
};
</script>

<style></style>
