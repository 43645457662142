var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-overlay',[_c('db-searchable-table',{attrs:{"items":_vm.assignments,"fields":_vm.fields,"total-rows":_vm.total,"searchable":""},on:{"changed":_vm.getData},scopedSlots:_vm._u([{key:"modalbutton",fn:function(){return [(!_vm.isCompanyArchived)?_c('app-button',{staticClass:"flex-1-md",attrs:{"icon":"PlusIcon","text":"Atama Yap","size":"md"},on:{"click":_vm.showAssignmentForm}}):_vm._e()]},proxy:true},{key:"physician",fn:function(ref){
var item = ref.item;
return [(item.physician)?_c('app-user-info',{attrs:{"title":item.physician.auth.fullname,"icon":"","variant":"secondary"}}):_c('span',[_vm._v(" - ")])]}},{key:"physicianConfirm",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":_vm.$variants[item.physicianConfirm]}},[_vm._v(" "+_vm._s(_vm.$capitalize(item.physicianConfirm))+" ")])]}},{key:"companyConfirm",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":_vm.$variants[item.companyConfirm]}},[_vm._v(" "+_vm._s(_vm.$capitalize(item.companyConfirm))+" ")])]}},{key:"isAssignmentActive",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.isAssignmentActive ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(item.isAssignmentActive ? "Aktif" : "Pasif")+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [(item.isAssignmentActive)?_c('app-icon-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Atamayı Kaldır'),expression:"'Atamayı Kaldır'",modifiers:{"hover":true,"bottom":true}}],attrs:{"variant":"gradient-danger","icon":"XIcon"},on:{"click":function($event){return _vm.deactiveAssignment(item)}}}):_c('span',[_vm._v(" - ")])]}}]),model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }