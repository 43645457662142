<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="employeeform">
      <branch-select v-model="data.branch" :company="selectedCompany" :reduce="(option) => option._id" required />
      <app-input v-model="data.fullname" name="Ad Soyad" label="Ad Soyad:" placeholder="Ad Soyad..." rules="required" />
      <app-mask-input v-model="data.tcNo" name="TC Kimlik No" input-mask="###########" label="TC Kimlik No:" placeholder="TC Kimlik No..." rules="required|min:11" />
      <app-input v-model="data.email" type="email" name="E-Posta" label="E-Posta:" placeholder="E-Posta..." rules="email" />
      <app-input v-model="data.phone" type="phone" name="Telefon" label="Telefon:" placeholder="Telefon..." />
      <app-input v-model="data.duty" name="Görevi" label="Görevi:" placeholder="Görevi..." rules="required" />
      <app-date-input v-model="data.dateOfStartWork" name="İşe Giriş Tarihi" label="İşe Giriş Tarihi:" placeholder="Tarih Seçiniz..." />
      <app-date-input v-model="data.dateOfEximination" name="İşe Giriş Muayene Tarihi" label="İşe Giriş Muayene Tarihi:" placeholder="Tarih Seçiniz..." />
      <app-date-input v-model="data.dateOfWorkSafetyTraining" name="İSG Eğitim Tarihi" label="İSG Eğitim Tarihi:" placeholder="Tarih Seçiniz..." />
      <hr />
      <app-button :text="updateItem ? 'Güncelle' : 'Oluştur'" block @click="updateItem ? updateEmployee() : newEmployee()" />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    updateItem() {
      return this.$store.getters.appModalItem;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
  },
  mounted() {
    if (this.updateItem) {
      this.data = { ...this.updateItem };
      this.data.branch = this.data?.branch?._id;
    } else this.data.branch = this.selectedBranch;
  },
  methods: {
    setData() {
      if (this.updateItem) ["_id", "confirmation", "isArchived", "createdAt", "updatedAt"].forEach((key) => delete this.data[key]);
      return {
        ...this.data,
        company: this.selectedCompany,
        phone: this.data.phone === "0" || !this?.data?.phone ? null : this.data.phone?.replace(/\D/g, ""),
      };
    },
    newEmployee() {
      this.$validate(this.$refs.employeeform, () => {
        this.$store.dispatch("newEmployee", this.setData());
      });
    },
    updateEmployee() {
      this.$validate(this.$refs.employeeform, () => {
        this.$store.dispatch("updateEmployee", { id: this.updateItem?._id, data: this.setData() });
      });
    },
  },
};
</script>
