<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="companyform">
      <app-input v-model="data.name" name="Firma Unvanı" label="Firma Unvanı:" placeholder="Firma Unvanı" rules="required" />
      <app-input v-model="data.contractNumber" name="Sözleşme Numarası" label="Sözleşme Numarası:" placeholder="Sözleşme Numarası" rules="required" />
      <app-date-input v-model="data.contractDate" name="Sözleşme Tarihi" label="Sözleşme Tarihi:" placeholder="Sözleşme Tarihi" rules="required" />
      <sectors v-model="data.sector" :reduce="(option) => option._id" required />
      <app-input v-model="data.taxOffice" name="Vergi Dairesi" label="Vergi Dairesi:" placeholder="Vergi Dairesi" rules="required" />
      <app-mask-input
        v-model="data.taxNo"
        input-mask="###########"
        name="Vergi/TC Numarası"
        type="number"
        label="Vergi/TC Numarası:"
        placeholder="Vergi/TC Numarası"
        rules="required|min:10"
      />
      <app-input v-model="data.naceCode" name="Nace Kodu" label="Nace Kodu:" placeholder="Nace Kodu..." rules="required" />
      <app-button text="Güncelle" block @click="updateCompany" />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters?.selectedCompany;
    },
  },
  mounted() {
    const notAllowedFields = ["_id", "id", "isCustomerFree", "employeeCount", "createdAt", "updatedAt", "company_id", "isArchived", "parasut_contact_id"];

    this.data = { ...this.selectedCompany, sector: this.selectedCompany?.sector?._id };
    for (const key in this.data) {
      if (notAllowedFields.includes(key)) {
        delete this.data[key];
      }
    }
  },
  methods: {
    setData() {
      return {
        ...this.data,
        contractDate: new Date(this.data.contractDate),
      };
    },
    updateCompany() {
      this.$validate(this.$refs.companyform, () => {
        this.$store.dispatch("updateCompany", {
          id: this.selectedCompany?._id,
          data: this.setData(),
        });
      });
    },
  },
};
</script>

<style></style>
