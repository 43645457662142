<template>
  <b-card body-class="p-1">
    <b-custom-tabs id="companyInfoTabs" :tabs="tabs" no-bottom-gap lazy />
  </b-card>
</template>

<script>
import CompanyInfo from "./CompanyInfo.vue";
import BranchList from "./branches/BranchList.vue";
import CompanyFileList from "./companyFiles/FileList.vue";
import locations from "./locations.vue";
import CustomerList from "@/views/Users/Customers/CustomerList.vue";
import CompanyContacts from "./contacts/CompanyContacts.vue";

export default {
  data() {
    return {
      tabs: [
        { title: "Genel Bilgiler", component: CompanyInfo },
        { title: "Siciller", component: BranchList },
        { title: "Evraklar", component: CompanyFileList },
        { title: "Sistem Kullanıcıları", component: CustomerList },
        { title: "Telefon Rehberi", component: CompanyContacts },
        { title: "Konumlar", component: locations },
      ],
    };
  },
};
</script>

<style></style>
