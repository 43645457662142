<template>
  <app-overlay loading-variable="tab">
    <b-alert variant="primary" :show="Boolean(selectedCompany && !selectedCompany.parasut_contact_id && message)">
      <div class="alert-body">
        <feather-icon class="mr-50" icon="InfoIcon" />
        <span v-if="message">
          {{ message }}
        </span>
      </div>
    </b-alert>
    <b-card v-if="selectedCompany && selectedCompany.parasut_contact_id" body-class="p-1" class="mb-50">
      <b-custom-tabs v-if="selectedCompany && selectedCompany.parasut_contact_id" id="companyFinanceTabs" :tabs="tabs" lazy> </b-custom-tabs>
    </b-card>
  </app-overlay>
</template>

<script>
import ContactTransactions from "@/views/Finance/contacts/ContactDetails/ContactTransactions.vue";
import ContactNotes from "@/views/Finance/contacts/ContactDetails/ContactNotes/ContactNotes.vue";
import Invoices from "@/views/Finance/invoices/Invoices.vue";
import InvoicePayments from "@/views/Finance/InvoicePayments/InvoicePayments.vue";
export default {
  data() {
    return {
      isLoading: false,
      message: null,
      tabs: [
        { title: "Açık Faturalar", component: Invoices },
        { title: "Hareketler", component: ContactTransactions },
        { title: "Tahsilatlar", component: InvoicePayments },
        { title: "Notlar", component: ContactNotes },
        { title: "Arama Geçmişi" },
        { title: "Ödeme Grafiği" },
      ],
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters?.selectedCompany;
    },
  },
  methods: {
    checkContactId() {
      if (!this.selectedCompany?.parasut_contact_id) {
        this.isLoading = true;
        this.$axios
          .get(`/finance/check-contact/${this.selectedCompany?._id}`, { loading: "tab" })
          .then((result) => {
            this.$store.commit("setSelectedCompany", {
              ...this.selectedCompany,
              parasut_contact_id: result?.data?.contact_id,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.message = err?.data?.message;
            console.log(this.message);
            this.isLoading = false;
          });
      }
    },
  },
  mounted() {
    this.checkContactId();
  },
};
</script>

<style></style>
