<template>
  <app-overlay loading-variable="sidebarOverlay">
    <small>
      - Sadece PDF, JPG ve PNG dosya formatı yüklenebilir. <br />
      - En fazla 2 MB boyutunda dosya yükleyebilirsiniz.
    </small>
    <hr />
    <validation-observer ref="fileinput">
      <app-input v-model="customName" name="Dosya Adı" label="Dosya Adı:" placeholder="Dosya Adı..." rules="required" />
      <app-select-input v-model="fileType" name="Dosya Türü" :options="options" label="Dosya Türü:" placeholder="Tür Seçiniz..." rules="required" />
      <app-file-input v-model="file" label="Dosya:" :accept="$acceptMimes(['jpg', 'jpeg', 'png', 'pdf'])" requires />
    </validation-observer>
    <hr />
    <app-button icon="UploadIcon" icon-position="left" size="md" text="Yükle" block @click="uploadFile" />
    <hr />
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data: () => ({
    file: null,
    customName: null,
    fileType: null,
    options: ["İmza Sirküleri", "Sözleşmeler", "Kapasite Raporu", "Kimlik", "ISG Katip Sözleşmesi"],
  }),
  computed: {
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
  },
  methods: {
    setData() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("customName", this.customName);
      formData.append("company", this.company);
      formData.append("fileType", this.fileType);
      return formData;
    },
    uploadFile() {
      this.$validate(this.$refs.fileinput, () => {
        this.$store.dispatch("uploadCompanyFile", this.setData());
      });
    },
  },
};
</script>

<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Dosya Seç";
}
</style>
