<template>
  <visit-layout>
    <template #list>
      <visit-list :fields="fields" :query="query" getterKey="visits" actionType="setVisits" />
    </template>
    <template #calendar>
      <visit-calendar :query="query" />
    </template>
  </visit-layout>
</template>

<script>
import VisitLayout from "@/views/Visits/visitLayout/VisitLayout.vue";
import VisitList from "@/views/Visits/visitLayout/VisitList.vue";
import VisitCalendar from "@/views/Visits/visitLayout/VisitCalendar.vue";

export default {
  components: { VisitLayout, VisitList, VisitCalendar },
  computed: {
    fields() {
      return [
        { key: "physician", label: "Görevli Personel" },
        ...(this.selectedBranch ? [] : [{ key: "branch.name", label: "Sicil" }]),
        {
          key: "startDate",
          label: "Ziyaret Tarihi",
          formatDate: true,
        },
        {
          key: "createdAt",
          label: "Oluşturma Tarihi",
          formatDateTime: true,
        },
        { key: "status", label: "Durum" },
      ];
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    query() {
      return {
        company: this.selectedCompany?._id,
        branch: this.selectedBranch ? this.selectedBranch : undefined,
      };
    },
  },
};
</script>

<style></style>
