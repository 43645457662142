<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="assignmentForm">
      <uncompleted-branch-list v-model="data.branch" :company="selectedCompany" :role="physicianRole" />
      <physician-select v-model="data.physician" :role="physicianRole" required />
      <hr />
      <app-button text="Atama Yap" block @click="makeAssignment" />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import UncompletedBranchList from "@/views/Riskomer/Employees/details/assignments/UncompletedBranchList.vue";

export default {
  components: { ValidationObserver, UncompletedBranchList },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    physicianRole() {
      return this.$store.getters.appSidebarItem?.physicianRole;
    },
    callback() {
      return this.$store.getters.appSidebarItem?.callback;
    },
  },
  methods: {
    setData() {
      return {
        company: this.selectedCompany,
        ...this.data,
      };
    },
    makeAssignment() {
      this.$validate(this.$refs.assignmentForm, () => {
        this.$store.dispatch("newAssignment", { data: this.setData(), callback: this.callback });
      });
    },
  },
};
</script>

<style></style>
