<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="createFromTemplateForm">
      <branch-select v-model="branch" :company="selectedCompany" :reduce="(option) => option._id" required />
      <app-file-input v-model="file" label="Dosya:" :accept="$acceptMimes(['xlsx'])" requires />
      <hr />
      <b-row style="gap: 10px 0">
        <b-col md="6">
          <app-button text="Yükle" icon="UploadIcon" block @click="createFromTemplate" />
        </b-col>
        <b-col md="6">
          <app-button @click="getEmployeeTemplate" text="Şablon İndir" icon="DownloadIcon" block />
        </b-col>
      </b-row>
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver },
  data() {
    return {
      branch: null,
      file: null,
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany?._id;
    },
    selectedBranch() {
      return this.$store.getters.selectedBranch;
    },
    query() {
      return this.$store.getters.appSidebarItem;
    },
  },
  mounted() {
    this.branch = this.selectedBranch;
  },
  methods: {
    setData() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("branch", this.branch);
      formData.append("company", this.selectedCompany);
      return formData;
    },
    createFromTemplate() {
      this.$validate(this.$refs.createFromTemplateForm, () => {
        this.$axios
          .post("/employees/create-from-file", this.setData(), { loading: "sidebar" })
          .then(() => {
            this.$emitter.$emit("Notification", { title: "Başarılı", variant: "success", message: "Çalışanlar sisteme başarıyla eklendi." });
            this.$store.dispatch("getEmployees", {
              query: this.query,
              loading: "table",
            });
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            console.log(err);
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message ?? null });
          });
      });
    },
    getEmployeeTemplate() {
      this.$axios
        .get("/employees/employee-template", { responseType: "blob", loading: "sidebar" })
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data], { type: result.headers["content-type"] }));
          const link = document.createElement("a");
          link.href = url;
          link.download = result.headers["content-disposition"]?.split("filename=")[1] ?? "toplu_calisan.xlsx";
          link.click();
        })
        .catch((err) => {
          console.log(err);
          this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message ?? null });
        });
    },
  },
};
</script>
