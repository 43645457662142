<template>
  <b-card body-class="p-1">
    <b-custom-tabs id="isgTabs" :tabs="tabs" lazy />
  </b-card>
</template>

<script>
import EmployeeList from "./employees/EmployeeList.vue";
import PlanList from "./plans/PlanList.vue";
import AssignmentCollapses from "./assignments/AssignmentCollapses.vue";
import detectionAndSuggestionList from "./actions/detectionAndSuggestion/detectionAndSuggestionList.vue";
import ObservationsList from "./actions/observations/ObservationsList.vue";
import incidentList from "./actions/incidents/incidentList.vue";
import TrainingList from "./trainings/TrainingList.vue";
import DocumentList from "./documents/DocumentList.vue";

export default {
  data() {
    return {
      tabs: [
        { title: "Çalışanlar", component: EmployeeList },
        { title: "Tespit / Öneri Defteri", component: detectionAndSuggestionList },
        { title: "Gözlemler", component: ObservationsList },
        { title: "Olaylar", component: incidentList },
        { title: "Eğitimler", component: TrainingList },
        { title: "Toplantılar" },
        { title: "Planlar", component: PlanList },
        { title: "Dokümanlar", component: DocumentList },
        { title: "Kontrol Listeleri" },
        { title: "Ekipmanlar" },
        { title: "Atamalar", component: AssignmentCollapses },
      ],
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
  },
  mounted() {
    if (this.selectedCompany?.isCustomerFree) {
      const index = this.tabs.findIndex((tab) => tab.title === "Atamalar");
      this.tabs.splice(index, 1);
    }
  },
};
</script>

<style></style>
